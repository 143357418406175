import React from "react"
import RacePageOneDay2022 from "../components/racepageOneDay2022"

const Race2022 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "Volta Ciclista a Catalunya",
      edition: 2022,
      what: "Race data",
      updated: "2022-03-21 09:14:49",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21801: {
        teamId: "21801",
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00c3\u00abn Team",
        teamNationCode: "FRA",
      },
      21803: {
        teamId: "21803",
        teamUciCode: "AST",
        teamName: "Astana Qazaqstan Team",
        teamNationCode: "KAZ",
      },
      21804: {
        teamId: "21804",
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
      },
      21809: {
        teamId: "21809",
        teamUciCode: "QST",
        teamName: "Quick-Step Alpha Vinyl Team",
        teamNationCode: "BEL",
      },
      21812: {
        teamId: "21812",
        teamUciCode: "EFE",
        teamName: "EF Education-EasyPost",
        teamNationCode: "USA",
      },
      21814: {
        teamId: "21814",
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
      },
      21818: {
        teamId: "21818",
        teamUciCode: "ISN",
        teamName: "Israel-Premier Tech",
        teamNationCode: "ISR",
      },
      21820: {
        teamId: "21820",
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
      },
      21823: {
        teamId: "21823",
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
      },
      21827: {
        teamId: "21827",
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
      },
      21830: {
        teamId: "21830",
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
      },
      21833: {
        teamId: "21833",
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "NED",
      },
      21837: {
        teamId: "21837",
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
      },
      21839: {
        teamId: "21839",
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
      },
      21843: {
        teamId: "21843",
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
      },
      21856: {
        teamId: "21856",
        teamUciCode: "IWG",
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        teamNationCode: "BEL",
      },
      21865: {
        teamId: "21865",
        teamUciCode: "ARK",
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        teamNationCode: "FRA",
      },
      22016: {
        teamId: "22016",
        teamUciCode: "EUS",
        teamName: "Euskaltel-Euskadi",
        teamNationCode: "ESP",
      },
      22245: {
        teamId: "22245",
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
      },
      22290: {
        teamId: "22290",
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
      },
      22480: {
        teamId: "22480",
        teamUciCode: "BEX",
        teamName: "Team BikeExchange-Jayco",
        teamNationCode: "AUS",
      },
      22925: {
        teamId: "22925",
        teamUciCode: "EKP",
        teamName: "Equipo Kern Pharma",
        teamNationCode: "ESP",
      },
      23399: {
        teamId: "23399",
        teamUciCode: "BBH",
        teamName: "Burgos-BH",
        teamNationCode: "ESP",
      },
      23455: {
        teamId: "23455",
        teamUciCode: "CJR",
        teamName: "Caja Rural-Seguros RGA",
        teamNationCode: "ESP",
      },
    },
    riders: {
      37446: {
        id: 37446,
        startno: 53,
        firstName: "Jai",
        lastName: "Hindley",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-05-05",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      6269: {
        id: 6269,
        startno: 123,
        firstName: "Jan",
        lastName: "Hirt",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1991-01-22",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      61354: {
        id: 61354,
        startno: 216,
        firstName: "Joel",
        lastName: "Nicolau",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-12-23",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      95523: {
        id: 95523,
        startno: 134,
        firstName: "Hugo",
        lastName: "Toumire",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "2001-10-05",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      45433: {
        id: 45433,
        startno: 26,
        firstName: "David",
        lastName: "Dekker",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-02",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      8078: {
        id: 8078,
        startno: 14,
        firstName: "Louis",
        lastName: "Vervaeke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-10-06",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      75478: {
        id: 75478,
        startno: 206,
        firstName: "Jacob",
        lastName: "Hindsgaul Madsen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2000-07-14",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      65570: {
        id: 65570,
        startno: 107,
        firstName: "Marc",
        lastName: "Brustenga",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-09-04",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      6242: {
        id: 6242,
        startno: 171,
        firstName: "Simon",
        lastName: "Yates",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1992-08-07",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      33236: {
        id: 33236,
        startno: 204,
        firstName: "Torstein",
        lastName: "Tr\u00c3\u00a6en",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1995-07-16",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      39387: {
        id: 39387,
        startno: 162,
        firstName: "Harm",
        lastName: "Vanhoucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-06-17",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      325: {
        id: 325,
        startno: 231,
        firstName: "Daniel",
        lastName: "Navarro",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-07-08",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      37388: {
        id: 37388,
        startno: 224,
        firstName: "Joan",
        lastName: "Bou",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-01-16",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      47841: {
        id: 47841,
        startno: 51,
        firstName: "Ben",
        lastName: "Zwiehoff",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-22",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      7771: {
        id: 7771,
        startno: 157,
        firstName: "Lukasz",
        lastName: "Owsian",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-02-24",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      76065: {
        id: 76065,
        startno: 7,
        firstName: "Lucas",
        lastName: "Plapp",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "2000-12-25",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      72967: {
        id: 72967,
        startno: 211,
        firstName: "Jhojan",
        lastName: "Garcia",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1998-01-10",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      46181: {
        id: 46181,
        startno: 196,
        firstName: "Francisco",
        lastName: "Galv\u00c3\u00a1n",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-12-01",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      37280: {
        id: 37280,
        startno: 5,
        firstName: "Pavel",
        lastName: "Sivakov",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-07-11",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      532: {
        id: 532,
        startno: 106,
        firstName: "Dario",
        lastName: "Cataldo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1985-03-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      26520: {
        id: 26520,
        startno: 74,
        firstName: "Matteo",
        lastName: "Badilatti",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1992-07-30",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      2211: {
        id: 2211,
        startno: 172,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      2276: {
        id: 2276,
        startno: 151,
        firstName: "Nairo",
        lastName: "Quintana",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-02-04",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      67846: {
        id: 67846,
        startno: 95,
        firstName: "Oier",
        lastName: "Lazkano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-11-07",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      22272: {
        id: 22272,
        startno: 77,
        firstName: "Bruno",
        lastName: "Armirail",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-04-11",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      64761: {
        id: 64761,
        startno: 234,
        firstName: "Alex",
        lastName: "Molenaar",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1999-07-13",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      76716: {
        id: 76716,
        startno: 184,
        firstName: "Henri",
        lastName: "Vandenabeele",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-04-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      1715: {
        id: 1715,
        startno: 176,
        firstName: "Christopher",
        lastName: "Juul-Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1989-07-06",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      11520: {
        id: 11520,
        startno: 217,
        firstName: "Eduard",
        lastName: "Prades",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-08-09",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      965: {
        id: 965,
        startno: 153,
        firstName: "Anthony",
        lastName: "Delaplace",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-11",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      22143: {
        id: 22143,
        startno: 102,
        firstName: "Antwan",
        lastName: "Tolhoek",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-04-29",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      602: {
        id: 602,
        startno: 125,
        firstName: "Jan",
        lastName: "Bakelants",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-02-14",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      32493: {
        id: 32493,
        startno: 105,
        firstName: "Amanuel",
        lastName: "Gebreigzabhier",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1994-08-17",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      1323: {
        id: 1323,
        startno: 136,
        firstName: "Jose",
        lastName: "Herrada",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1985-10-01",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      111801: {
        id: 111801,
        startno: 187,
        firstName: "Marco",
        lastName: "Brenner",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2002-08-27",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      82572: {
        id: 82572,
        startno: 84,
        firstName: "Corbin",
        lastName: "Strong",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "2000-04-30",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      16587: {
        id: 16587,
        startno: 116,
        firstName: "Alexandr",
        lastName: "Riabushenko",
        nationCode: "BLR",
        nationName: "Belarus",
        birthDate: "1995-10-12",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      73811: {
        id: 73811,
        startno: 197,
        firstName: "Roger",
        lastName: "Adri\u00c3\u00a1",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-04-18",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      32201: {
        id: 32201,
        startno: 227,
        firstName: "Antonio",
        lastName: "Soto",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-09-22",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      14791: {
        id: 14791,
        startno: 11,
        firstName: "Fausto",
        lastName: "Masnada",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-11-06",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      37777: {
        id: 37777,
        startno: 36,
        firstName: "Ivo",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      568: {
        id: 568,
        startno: 86,
        firstName: "Simon",
        lastName: "Clarke",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-07-18",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      58639: {
        id: 58639,
        startno: 54,
        firstName: "Sergio",
        lastName: "Higuita",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-08-01",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      38: {
        id: 38,
        startno: 91,
        firstName: "Alejandro",
        lastName: "Valverde",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1980-04-25",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      57961: {
        id: 57961,
        startno: 64,
        firstName: "Clement",
        lastName: "Berthet",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-08-02",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      20378: {
        id: 20378,
        startno: 122,
        firstName: "Simone",
        lastName: "Petilli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-04",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      3356: {
        id: 3356,
        startno: 111,
        firstName: "Joe",
        lastName: "Dombrowski",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1991-05-12",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      19784: {
        id: 19784,
        startno: 41,
        firstName: "Jack",
        lastName: "Haig",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-09-06",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      16608: {
        id: 16608,
        startno: 212,
        firstName: "Michal",
        lastName: "Schlegel",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1995-05-31",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      31288: {
        id: 31288,
        startno: 215,
        firstName: "Alvaro",
        lastName: "Cuadros",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-04-12",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      9154: {
        id: 9154,
        startno: 133,
        firstName: "Pierre-Luc",
        lastName: "P\u00c3\u00a9richon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1987-01-04",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      2324: {
        id: 2324,
        startno: 25,
        firstName: "Tom",
        lastName: "Dumoulin",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1990-11-11",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      76474: {
        id: 76474,
        startno: 13,
        firstName: "Ilan",
        lastName: "Van Wilder",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "2000-05-14",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      27350: {
        id: 27350,
        startno: 205,
        firstName: "Jonas",
        lastName: "Gregaard",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-07-30",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      54660: {
        id: 54660,
        startno: 104,
        firstName: "Juan Pedro",
        lastName: "Lopez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-07-31",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      76709: {
        id: 76709,
        startno: 15,
        firstName: "Ethan",
        lastName: "Vernon",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "2000-08-26",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      67838: {
        id: 67838,
        startno: 203,
        firstName: "Idar",
        lastName: "Andersen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-04-30",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      1783: {
        id: 1783,
        startno: 161,
        firstName: "Thomas",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1986-11-06",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      45113: {
        id: 45113,
        startno: 235,
        firstName: "Adria",
        lastName: "Moreno Sala",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1991-08-19",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      37392: {
        id: 37392,
        startno: 223,
        firstName: "Gotzon",
        lastName: "Martin",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-02-15",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      163759: {
        id: 163759,
        startno: 56,
        firstName: "Anton",
        lastName: "Palzer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-03-11",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      3224: {
        id: 3224,
        startno: 57,
        firstName: "Cesare",
        lastName: "Benedetti",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1987-08-03",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      61639: {
        id: 61639,
        startno: 92,
        firstName: "Ivan",
        lastName: "Sosa",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1997-10-31",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      46617: {
        id: 46617,
        startno: 195,
        firstName: "Jaime",
        lastName: "Castrillo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-03-13",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      14712: {
        id: 14712,
        startno: 183,
        firstName: "Romain",
        lastName: "Combaud",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1991-04-01",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      20384: {
        id: 20384,
        startno: 103,
        firstName: "Giulio",
        lastName: "Ciccone",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-12-20",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      6184: {
        id: 6184,
        startno: 72,
        firstName: "Sebastien",
        lastName: "Reichenbach",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1989-05-28",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      6410: {
        id: 6410,
        startno: 173,
        firstName: "Damien",
        lastName: "Howson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1992-08-13",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      3213: {
        id: 3213,
        startno: 142,
        firstName: "Esteban",
        lastName: "Chaves",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1990-01-17",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      7011: {
        id: 7011,
        startno: 97,
        firstName: "Albert",
        lastName: "Torres",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-04-26",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      17203: {
        id: 17203,
        startno: 76,
        firstName: "Quentin",
        lastName: "Pacher",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1992-01-06",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      1615: {
        id: 1615,
        startno: 233,
        firstName: "Jetse",
        lastName: "Bol",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-09-08",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      55807: {
        id: 55807,
        startno: 47,
        firstName: "Hermann",
        lastName: "Pernsteiner",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1990-08-07",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      16615: {
        id: 16615,
        startno: 83,
        firstName: "Mads",
        lastName: "W\u00c3\u00bcrtz Schmidt",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-31",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      868: {
        id: 868,
        startno: 214,
        firstName: "Mikel",
        lastName: "Nieve",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-05-26",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      16635: {
        id: 16635,
        startno: 152,
        firstName: "Elie",
        lastName: "Gesbert",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1995-07-01",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      929: {
        id: 929,
        startno: 44,
        firstName: "Wout",
        lastName: "Poels",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-10-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      5515: {
        id: 5515,
        startno: 124,
        firstName: "Laurens",
        lastName: "Huys",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-09-24",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      33263: {
        id: 33263,
        startno: 147,
        firstName: "Jonathan",
        lastName: "Caicedo",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-03-28",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      2325: {
        id: 2325,
        startno: 132,
        firstName: "Jesus",
        lastName: "Herrada",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1990-07-26",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      7998: {
        id: 7998,
        startno: 165,
        firstName: "Matthew",
        lastName: "Holmes",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-12-08",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      67824: {
        id: 67824,
        startno: 186,
        firstName: "Jonas Iversby",
        lastName: "Hvideberg",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-02-09",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      51348: {
        id: 51348,
        startno: 167,
        firstName: "Sylvain",
        lastName: "Moniquet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-14",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      63586: {
        id: 63586,
        startno: 237,
        firstName: "Manuel",
        lastName: "Pe\u00c3\u00b1alver",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1998-12-10",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      107572: {
        id: 107572,
        startno: 226,
        firstName: "Carlos",
        lastName: "Canal",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-06-28",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      65240: {
        id: 65240,
        startno: 12,
        firstName: "Andrea",
        lastName: "Bagioli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1999-03-23",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      114: {
        id: 114,
        startno: 32,
        firstName: "Rui",
        lastName: "Costa",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1986-10-05",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      42186: {
        id: 42186,
        startno: 61,
        firstName: "Ben",
        lastName: "O'Connor",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1995-11-25",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      81835: {
        id: 81835,
        startno: 46,
        firstName: "Santiago",
        lastName: "Buitrago",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1999-09-26",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      57357: {
        id: 57357,
        startno: 191,
        firstName: "Jos\u00c3\u00a9 F\u00c3\u00a9lix",
        lastName: "Parra",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-01-16",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      37717: {
        id: 37717,
        startno: 62,
        firstName: "Dorian",
        lastName: "Godon",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-05-25",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      20511: {
        id: 20511,
        startno: 117,
        firstName: "Valerio",
        lastName: "Conti",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-03-30",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      14123: {
        id: 14123,
        startno: 63,
        firstName: "Lawrence",
        lastName: "Warbasse",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1990-06-28",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      2322: {
        id: 2322,
        startno: 21,
        firstName: "Rohan",
        lastName: "Dennis",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-05-28",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      6496: {
        id: 6496,
        startno: 156,
        firstName: "Winner",
        lastName: "Anacona",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1988-08-11",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      84926: {
        id: 84926,
        startno: 4,
        firstName: "Carlos",
        lastName: "Rodriguez",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-02-02",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      10990: {
        id: 10990,
        startno: 131,
        firstName: "Guillaume",
        lastName: "Martin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-06-09",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      16988: {
        id: 16988,
        startno: 23,
        firstName: "Sam",
        lastName: "Oomen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-08-15",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      3165: {
        id: 3165,
        startno: 16,
        firstName: "Pieter",
        lastName: "Serry",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-21",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      46164: {
        id: 46164,
        startno: 202,
        firstName: "Anthon",
        lastName: "Charmig",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-03-25",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      25726: {
        id: 25726,
        startno: 37,
        firstName: "Marc",
        lastName: "Soler",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-11-22",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      69145: {
        id: 69145,
        startno: 181,
        firstName: "Mark",
        lastName: "Donovan",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-04-03",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      87900: {
        id: 87900,
        startno: 201,
        firstName: "Tobias Halland",
        lastName: "Johannessen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-08-23",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      2503: {
        id: 2503,
        startno: 137,
        firstName: "Sander",
        lastName: "Armee",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-12-10",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      46756: {
        id: 46756,
        startno: 232,
        firstName: "Oscar",
        lastName: "Cabedo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-11-12",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      2297: {
        id: 2297,
        startno: 222,
        firstName: "Luis Angel",
        lastName: "Mate",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1984-03-23",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      16816: {
        id: 16816,
        startno: 45,
        firstName: "Phil",
        lastName: "Bauhaus",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-07-08",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      2961: {
        id: 2961,
        startno: 3,
        firstName: "Michal",
        lastName: "Kwiatkowski",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1990-06-02",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      16569: {
        id: 16569,
        startno: 81,
        firstName: "Michael",
        lastName: "Woods",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1986-10-12",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      45082: {
        id: 45082,
        startno: 193,
        firstName: "Hector",
        lastName: "Carretero",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-05-28",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      37761: {
        id: 37761,
        startno: 66,
        firstName: "Jaakko",
        lastName: "H\u00c3\u00a4nninen",
        nationCode: "FIN",
        nationName: "Finland",
        birthDate: "1997-04-16",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      2338: {
        id: 2338,
        startno: 42,
        firstName: "Sonny",
        lastName: "Colbrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-17",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      6415: {
        id: 6415,
        startno: 121,
        firstName: "Louis",
        lastName: "Meintjes",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1992-02-21",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      2978: {
        id: 2978,
        startno: 175,
        firstName: "Michael",
        lastName: "Hepburn",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-08-17",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      117: {
        id: 117,
        startno: 141,
        firstName: "Rigoberto",
        lastName: "Uran",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1987-01-26",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      116: {
        id: 116,
        startno: 94,
        firstName: "Jos\u00c3\u00a9 Joaquin",
        lastName: "Rojas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1985-06-08",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      6222: {
        id: 6222,
        startno: 75,
        firstName: "Rudy",
        lastName: "Molard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1989-09-17",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      45445: {
        id: 45445,
        startno: 114,
        firstName: "Vadim",
        lastName: "Pronskiy",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1998-06-04",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      37447: {
        id: 37447,
        startno: 71,
        firstName: "Michael",
        lastName: "Storer",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1997-02-28",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      37219: {
        id: 37219,
        startno: 182,
        firstName: "Casper",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1996-03-15",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      27308: {
        id: 27308,
        startno: 213,
        firstName: "Fernando",
        lastName: "Barcel\u00c3\u00b3",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1996-01-06",
        teamId: 23455,
        teamName: "Caja Rural-Seguros RGA",
        stillInTheRace: "Y",
      },
      41368: {
        id: 41368,
        startno: 135,
        firstName: "Alexandre",
        lastName: "Delettre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1997-10-25",
        teamId: 22290,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      41: {
        id: 41,
        startno: 27,
        firstName: "Robert",
        lastName: "Gesink",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1986-05-31",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      8068: {
        id: 8068,
        startno: 43,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamId: 21804,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      23097: {
        id: 23097,
        startno: 2,
        firstName: "Richard",
        lastName: "Carapaz",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1993-05-29",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      153042: {
        id: 153042,
        startno: 113,
        firstName: "Javier",
        lastName: "Romo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1999-01-06",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      18059: {
        id: 18059,
        startno: 143,
        firstName: "Hugh",
        lastName: "Carthy",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1994-07-09",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      37301: {
        id: 37301,
        startno: 207,
        firstName: "Torjus",
        lastName: "Sleen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1997-03-30",
        teamId: 21843,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      47176: {
        id: 47176,
        startno: 166,
        firstName: "Viktor",
        lastName: "Verschaeve",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-08-03",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      86651: {
        id: 86651,
        startno: 236,
        firstName: "Ander",
        lastName: "Okamika",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1993-04-02",
        teamId: 23399,
        teamName: "Burgos-BH",
        stillInTheRace: "Y",
      },
      61176: {
        id: 61176,
        startno: 225,
        firstName: "Unai",
        lastName: "Cuadrado",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-09-26",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      69890: {
        id: 69890,
        startno: 73,
        firstName: "Attila",
        lastName: "Valter",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-06-12",
        teamId: 21814,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      50303: {
        id: 50303,
        startno: 31,
        firstName: "Joao",
        lastName: "Almeida",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1998-08-05",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      563: {
        id: 563,
        startno: 1,
        firstName: "Richie",
        lastName: "Porte",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1985-01-30",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      34624: {
        id: 34624,
        startno: 34,
        firstName: "Sebastian",
        lastName: "Molano",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-04-11",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      114755: {
        id: 114755,
        startno: 194,
        firstName: "Ra\u00c3\u00bal",
        lastName: "Garc\u00c3\u00ada",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2001-02-23",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      20448: {
        id: 20448,
        startno: 67,
        firstName: "Nans",
        lastName: "Peters",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-03-12",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      27277: {
        id: 27277,
        startno: 112,
        firstName: "Simone",
        lastName: "Velasco",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-12-02",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      27190: {
        id: 27190,
        startno: 96,
        firstName: "William",
        lastName: "Barta",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-01-04",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      42175: {
        id: 42175,
        startno: 177,
        firstName: "Callum",
        lastName: "Scotson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1996-08-10",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      44591: {
        id: 44591,
        startno: 154,
        firstName: "Miguel",
        lastName: "Florez",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1996-02-21",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      871: {
        id: 871,
        startno: 6,
        firstName: "Jonathan",
        lastName: "Castroviejo",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1987-04-27",
        teamId: 21827,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      68393: {
        id: 68393,
        startno: 127,
        firstName: "Th\u00c3\u00a9o",
        lastName: "Delacroix",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-02-21",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      730: {
        id: 730,
        startno: 22,
        firstName: "Steven",
        lastName: "Kruijswijk",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1987-06-07",
        teamId: 21830,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      752: {
        id: 752,
        startno: 17,
        firstName: "Dries",
        lastName: "Devenyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1983-07-22",
        teamId: 21809,
        teamName: "Quick-Step Alpha Vinyl Team",
        stillInTheRace: "Y",
      },
      77103: {
        id: 77103,
        startno: 101,
        firstName: "Mattias",
        lastName: "Skjelmose Jensen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "2000-09-26",
        teamId: 21837,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      114659: {
        id: 114659,
        startno: 35,
        firstName: "Juan",
        lastName: "Ayuso",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "2002-09-16",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      32892: {
        id: 32892,
        startno: 155,
        firstName: "Hugo",
        lastName: "Hofstetter",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-02-13",
        teamId: 21865,
        teamName: "Team Ark\u00c3\u00a9a-Samsic",
        stillInTheRace: "Y",
      },
      17252: {
        id: 17252,
        startno: 144,
        firstName: "Odd Christian",
        lastName: "Eiking",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-12-28",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      18249: {
        id: 18249,
        startno: 126,
        firstName: "Tom",
        lastName: "Devriendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-29",
        teamId: 21856,
        teamName: "Intermarch\u00c3\u00a9-Wanty-Gobert Mat\u00c3\u00a9riaux",
        stillInTheRace: "Y",
      },
      18117: {
        id: 18117,
        startno: 145,
        firstName: "Merhawi",
        lastName: "Kudus",
        nationCode: "ERI",
        nationName: "Eritrea",
        birthDate: "1994-01-23",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
      7635: {
        id: 7635,
        startno: 221,
        firstName: "Mikel",
        lastName: "Bizkarra",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-08-21",
        teamId: 22016,
        teamName: "Euskaltel-Euskadi",
        stillInTheRace: "Y",
      },
      77148: {
        id: 77148,
        startno: 185,
        firstName: "Marius",
        lastName: "Mayrhofer",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "2000-09-18",
        teamId: 21833,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      3352: {
        id: 3352,
        startno: 33,
        firstName: "George",
        lastName: "Bennett",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1990-04-07",
        teamId: 21839,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      1937: {
        id: 1937,
        startno: 87,
        firstName: "Guillaume",
        lastName: "Boivin",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1989-05-25",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      57355: {
        id: 57355,
        startno: 192,
        firstName: "Urko",
        lastName: "Berrade",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1997-11-28",
        teamId: 22925,
        teamName: "Equipo Kern Pharma",
        stillInTheRace: "Y",
      },
      16506: {
        id: 16506,
        startno: 55,
        firstName: "Martin",
        lastName: "Laas",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1993-09-15",
        teamId: 22245,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      3103: {
        id: 3103,
        startno: 82,
        firstName: "Alessandro",
        lastName: "De Marchi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1986-05-19",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      706: {
        id: 706,
        startno: 85,
        firstName: "Daryl",
        lastName: "Impey",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1984-12-06",
        teamId: 21818,
        teamName: "Israel-Premier Tech",
        stillInTheRace: "Y",
      },
      61988: {
        id: 61988,
        startno: 174,
        firstName: "Kaden",
        lastName: "Groves",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-12-23",
        teamId: 22480,
        teamName: "Team BikeExchange-Jayco",
        stillInTheRace: "Y",
      },
      9114: {
        id: 9114,
        startno: 115,
        firstName: "Sebastian",
        lastName: "Henao",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1993-08-05",
        teamId: 21803,
        teamName: "Astana Qazaqstan Team",
        stillInTheRace: "Y",
      },
      7781: {
        id: 7781,
        startno: 93,
        firstName: "Carlos",
        lastName: "Verona",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1992-11-04",
        teamId: 21823,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      349: {
        id: 349,
        startno: 65,
        firstName: "Mika\u00c3\u00abl",
        lastName: "Ch\u00c3\u00a9rel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-03-17",
        teamId: 21801,
        teamName: "AG2R Citro\u00c3\u00abn Team",
        stillInTheRace: "Y",
      },
      46012: {
        id: 46012,
        startno: 164,
        firstName: "Filippo",
        lastName: "Conca",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-09-22",
        teamId: 21820,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      37494: {
        id: 37494,
        startno: 146,
        firstName: "Neilson",
        lastName: "Powless",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "1996-09-03",
        teamId: 21812,
        teamName: "EF Education-EasyPost",
        stillInTheRace: "Y",
      },
    },
  }

  const race = "Volta Catalunya"
  const raceID = 14

  return (
    <RacePageOneDay2022
      title={`${race} 2022`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2022
